import "../styles/layouts/logistic.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import HeroService from "../components/Hero/HeroService/HeroService";
import Layout from "../components/Layout/layout";
import OurTargetLogistics from "../components/OurTarget/OurTargetLogistics";
import { SEO } from "../components/SEO/SEO";
import ServiceMarkupSchema from "../components/SEO/ServiceMarkupSchema";
import ServicesResume, { ServiceResume } from "../components/ServicesResumen/ServicesResume";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import Video from "../components/Video/Video";
import { Problem } from "../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../components/WeTalk/WeTalk";
import { Image, Photo, Slug } from "../types/shared";

const LogisticPage = ({ data }: PageProps<Queries.LogisticPageQuery>) => {
  const photoHero = data.sanityPageLogistics?.hero?.photo as Photo;
  const servicesResumenList: ServiceResume[] = [];
  data.allSanityTemplateLogisticsServices.nodes.forEach((service) => {
    if (!service.hero?.title || !service.seo?.thumbnail) {
      return;
    }
    servicesResumenList.push({
      name: service.hero.title,
      photo: { image: service.seo.thumbnail, alt: `Foto de portada de ${service.hero.title}` } as unknown as Photo,
      slug: { current: `/logistica/${service.slug?.current}/` } as Slug,
    });
  });

  const has_video = !!data.sanityPageLogistics?.video?.code;
  let videoThumbnail: Photo | null = null;
  if (has_video) {
    const alt = data.sanityPageLogistics.video?.alt ? data.sanityPageLogistics.video?.alt : "";
    videoThumbnail = {
      image: data.sanityPageLogistics.video?.image as unknown as Image,
      alt,
    };
  }

  return (
    <Layout>
      <div className="l-logistic">
        <HeroService
          title={data.sanityPageLogistics?.hero?.title}
          accompanimentTitle={data.sanityPageLogistics?.hero?.resume}
          subtitle={data.sanityPageLogistics?.hero?.subtitle}
          resume={data.sanityPageLogistics?.hero?.text}
          photo={photoHero}
        />
        <ServicesResume
          servicesList={servicesResumenList}
          is_firstBlue={true}
        />
        {/*<BrandsLogistics />*/}
        <OurTargetLogistics />
        {has_video && (
          <Video
            code={data.sanityPageLogistics.video.code}
            photo={videoThumbnail}
          />
        )}
        <WeKnowYourProblems
          title={data.sanityPageLogistics?.weKnowYourProblems?.title}
          problemsList={data.sanityPageLogistics?.weKnowYourProblems?.PAQsList as Problem[]}
        />
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default LogisticPage;

export const Head = ({ data }: PageProps<Queries.LogisticPageQuery>) => (
  <>
    <SEO
      title={data.sanityPageLogistics?.seo?.title}
      robots={data.sanityPageLogistics?.seo?.seo_robots}
      description={data.sanityPageLogistics?.seo?.description}
      canonical={data.sanityPageLogistics?.seo?.canonical}
      imageAbsolutePath={data.sanityPageLogistics?.seo?.thumbnail?.asset?.url}
    />
    <ServiceMarkupSchema
      titleHero={data.sanityPageLogistics?.hero?.title}
      descriptionService={data.sanityPageLogistics?.seo?.description}
      absolutePathImageOG={data.sanityPageLogistics?.seo?.thumbnail?.asset?.url}
      absolutePathService={`${data.sanityDocumentData?.urlSite}logistica`}
      problemList={data.sanityPageLogistics?.weKnowYourProblems?.PAQsList as Problem[]}
    />
  </>
);

export const query = graphql`
  query LogisticPage {
    sanityDocumentData {
      urlSite
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    sanityPageLogistics {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      hero {
        title
        text: _rawText
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        subtitle
        resume
      }
      video {
        code
        alt
        image {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
      weKnowYourProblems {
        title
        PAQsList {
          question
          answer: _rawAnswer
        }
      }
    }
    allSanityTemplateLogisticsServices {
      nodes {
        seo {
          thumbnail {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        hero {
          title
        }
        slug {
          current
        }
      }
    }
  }
`;
